import Country, { KoalaCollectionConfigs } from "src/helpers/localization-helpers/countryClass";
import { SupportedPaymentMethod } from "src/types/enums/paymentMethods.enum";
import { creditsConfig } from "../credits.config";
import { CountryName } from "src/types/localization/CountryName";
import { CountryCode } from "src/types/localization/CountryCode";
import { Currency } from "src/types/localization/Currency";
import { LanguageName } from "src/types/localization/LanguageName";
import { LanguageCode } from "src/types/localization/LanguageCode";
import { bundleDiscountSystems } from "../bundle/defaultBundleDiscountSystems";
import consts from "../consts";

const env = process.env.NEXT_PUBLIC_ENV;

const shopifyKeys = {
  STORE_NAME: process.env.NEXT_PUBLIC_SHOPIFY_STORE_NAME_SA ?? "",
  STOREFRONT_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN_SA ?? "",
  ADMIN_TOKEN: process.env.SHOPIFY_ADMIN_TOKEN_SA ?? "",
};

const collections = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 281247612972,
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_SINGLE_PRODUCTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431158940 : 281247547436,
    name: "all_single",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ALL_PACKS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 289431060636 : 281247645740,
    name: "packs",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298026959004 : 281247612972,
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751806108 : 281247612972,
    name: "ladies",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_TEENS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297688531100 : 281247612972,
    name: "teens",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_GIFTS: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 298701947036 : 0,
    name: "gifts",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ACCESSORIES: {
    id: env === "development" ? 275279937672 : env === "staging" || env === "ci" ? 297751740572 : 281247612972,
    name: "accessories",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
};

const koalaCollections: KoalaCollectionConfigs = {
  COLLECTION_ALL_ACTIVE_PRODUCTS: {
    id: ["production", "preproduction"].includes(env) ? "66614bb3d9470fe2c0ea8a6d" : "6659eb7c7d16c96d87f57d1c",
    name: "all",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_UNDERWEARS: {
    id: ["production", "preproduction", "staging"].includes(env)
      ? "66614c04d9470fe2c0ea8a7e"
      : "6659ecee7d16c96d87f57dec",
    name: "underwears",
    isActive: true,
    hasSeparateList: false,
    isCategory: false,
    isDefault: true,
    isInPopover: true,
    hasBundleDiscount: true,
  },
  COLLECTION_LADIES: {
    id: "",
    name: "ladies",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_TEENS: {
    id: "",
    name: "teens",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
  COLLECTION_ACCESSORIES: {
    id: "",
    name: "accessories",
    isActive: false,
    hasSeparateList: false,
    isCategory: false,
    isDefault: false,
    isInPopover: false,
    hasBundleDiscount: false,
  },
};

const shipping = {
  line: {
    title: "Standard",
    price: "10.00",
  },
  fees: 10,
  freeLimit: 240,
};

const socialInfo = {
  WHATSAPP_SUPPORT_NUMBER: "+971585096963",
  FACEBOOK_PAGE_HANDLE: "myshemsi",
  INSTAGRAM_HANDLE: "myshemsi",
  YOUTUBE_CHANNEL: "UCS0B5xRuLR8KZTsQTmDmrHg",
  TIKTOK_CHANNEL: "myshemsi",
  SUPPORT_START_HOUR: 8,
  SUPPORT_END_HOUR: 1,
};

const productsData = [
  {
    internalName: "ipanema",
    shopifyIds: [
      // Dev
      7060457160840, 7075133456520,

      // Prod
      7933561700579,

      // Prod packs
      7937883013347,

      // staging single
      7481930645660,

      // staging packs
      7481929564316,

      // shemsi SA
      7018018799660, 7018018471980,
    ],
    ratings: 12500,
  },
  {
    internalName: "bahia",
    shopifyIds: [
      // Dev
      7060425605256, 7075128737928,

      // Prod
      7937881014499,

      // Prod packs
      7937882947811,

      // staging single
      7481930383516,

      // staging packs
      7481929891996,

      // shemsi SA
      7018018701356, 7018018570284,
    ],
    ratings: 5600,
  },
  {
    internalName: "alexandria",
    shopifyIds: [
      // Dev
      7060417052808, 7075132670088,

      // Prod
      7937880948963,

      // Prod pack alexandria
      7937882915043,

      // staging single
      7481930481820,

      // staging packs
      7481930121372,

      // shemsi SA
      7018018537516, 7018018668588,
    ],
    ratings: 7520,
  },
  {
    internalName: "havana",
    shopifyIds: [
      // Dev
      7075118612616, 7060449493128,

      // Prod
      7937881112803,

      // Prod pack
      7937882980579,

      // staging single
      7481930285212,

      // staging packs
      7481929760924,

      // shemsi SA
      7018018635820, 7018018766892,
    ],
    ratings: 9500,
  },
];

// Label field should be localized
const provinces = [
  { label: "abha", value: "Abha" },
  { label: "ad_dammam", value: "Ad Dammam" },
  { label: "ad_dilam", value: "Ad Dilam" },
  { label: "al_abwa", value: "Al Abwa" }, //
  { label: "al_artaweeiyah", value: "Al Artaweeiyah" }, //
  { label: "al_bukayriyah", value: "Al Bukayriyah" },
  { label: "al_bahah", value: "Al Bahah" },
  { label: "al_hareeq", value: "Al Hareeq" },
  { label: "al_hasa", value: "Al Hasa" },
  { label: "al_jawf", value: "Al Jawf" }, //
  { label: "al_kharj", value: "Al Kharj" },
  { label: "al_khubar", value: "Al Khubar" },
  { label: "al_majmaah", value: "Al Majmaah" },
  { label: "al_mikhwah", value: "Al Mikhwah" }, //
  { label: "al_mubaraz", value: "Al Mubarraz" },
  { label: "al_namas", value: "Al Namas" }, //
  { label: "al_oyoon", value: "Al Oyoon" }, //
  { label: "al_qadeem", value: "Al Qadeem" }, //
  { label: "al_qatif", value: "Al Qatif" },
  { label: "al_qunfudhah", value: "Al Qunfudhah" },
  { label: "al_saih", value: "Al Saih" }, //
  { label: "al_taif", value: "Al Taif" },
  { label: "arar", value: "Ar Ar" },
  { label: "al_qasim", value: "Al Qasim" },
  { label: "buraydah", value: "Buraydah" },
  { label: "dhahran", value: "Dhahran" },
  { label: "hail", value: "Hail" },
  { label: "hofuf", value: "Hofuf" },
  { label: "jazen", value: "Jazan" },
  { label: "jeddah", value: "Jeddah" },
  { label: "jizah", value: "Jizah" }, //
  { label: "khamis_mushayt", value: "Khamis Mushayt" },
  { label: "king_khalid_military_city", value: "King Khalid Military City" }, //
  { label: "mecca", value: "Mecca" },
  { label: "medina", value: "Medina" },
  { label: "najran", value: "Najran" },
  { label: "ras_tanura", value: "Ras Tanura" },
  { label: "riyadh", value: "Riyadh" },
  { label: "sakaka", value: "Sakaka" },
  { label: "tabuk", value: "Tabuk" },
  { label: "yanbu", value: "Yanbu" },
];

const SA = new Country({
  name: CountryName["Saudi Arabia"],
  code: CountryCode.SA,
  currency: Currency.SAR,
  numberOfCents: 100,
  region: "MENA",
  phoneExample: "0595695812",
  provinces,
  provincyRequiredInCheckout: true,
  zipRequiredInCheckout: true,
  supportedLanguages: [
    {
      label: LanguageName.العربية,
      value: LanguageCode.ar,
    },
    {
      label: LanguageName.English,
      value: LanguageCode.en,
      default: true,
    },
  ],
  checkoutMethod: "in-house",
  collections,
  koalaCollections,
  productsData,
  freeShipping: true,
  shipping,
  shopifyKeys,
  omsConnector: "shopify-ksa",
  socialInfo,
  creditsConfig,
  referralConfig: {
    newRefereeOnly: true,
    referralCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
    refereeCredits: {
      amount: 50,
      amountType: "fixed_amount",
    },
  },
  paymentMethods: [
    SupportedPaymentMethod.cashOnDelivery,
    SupportedPaymentMethod.creditCard,
    SupportedPaymentMethod.tabby,
  ],
  bundleDiscountSystems,
  splitTestDiscount: {
    discountAmount: consts.FAKE_DISCOUNT_PERCENTAGE_VALUE,
    discountType: "percentage",
  },
  hasPricesAbTest: false,
  christmasTheme: false,
  multiCategory: false,
  multiColors: false,
  pargoPickupPoints: false,
  hasGiftCards: false,
  hasAccessories: false,
  ramadanTheme: false,
  areDiscountsManagedFromShopify: false,
  isDiscountCodeAllowed: true,
  homeHeroImage: ["production", "preproduction"].includes(env)
    ? 7018018537516
    : ["staging", "ci"].includes(env)
    ? 7481930481820
    : 7060417052808,
  // oms: ["production", "preproduction", "ci"].includes(env) ? "shopify" : "koala",
  oms: "koala",
  orderScopes: ["fulfil", "nps_free", "referral"],
  pl3Connector: "khazenly-ksa",
});

export default SA;
