import { BundleDiscountSystem } from "src/types/bundle-discounts/BundleDiscountSystem";
import { BundleDiscountType } from "src/types/bundle-discounts/BundleDiscountType";

export const bundleDiscountSystems: BundleDiscountSystem[] = [
  {
    type: BundleDiscountType.bundle3,
    minimumItems: 3,
    range: ["3"],
    discountsPerItem: {
      // ipanema
      C01BM: {
        amount: 15,
        type: "percentage",
      },
      C01CM: {
        amount: 15,
        type: "percentage",
      },
      C01PM: {
        amount: 15,
        type: "percentage",
      },
      C01VM: {
        amount: 15,
        type: "percentage",
      },
      C01DM: {
        amount: 15,
        type: "percentage",
      },
      // ipanema+
      C01BF: {
        amount: 15,
        type: "percentage",
      },
      C01CF: {
        amount: 15,
        type: "percentage",
      },
      C01PF: {
        amount: 15,
        type: "percentage",
      },
      C01VF: {
        amount: 15,
        type: "percentage",
      },
      C01DF: {
        amount: 15,
        type: "percentage",
      },
      // alexandria
      C03BM: {
        amount: 15,
        type: "percentage",
      },
      // alexandria+
      C03BF: {
        amount: 15,
        type: "percentage",
      },
      // bahia
      C02BM: {
        amount: 15,
        type: "percentage",
      },
      // bahia+
      C02BF: {
        amount: 15,
        type: "percentage",
      },
      // havena
      C04BF: {
        amount: 15,
        type: "percentage",
      },
      C04CF: {
        amount: 15,
        type: "percentage",
      },
      C04PF: {
        amount: 15,
        type: "percentage",
      },
      C04VF: {
        amount: 15,
        type: "percentage",
      },
      C04DF: {
        amount: 15,
        type: "percentage",
      },
      // copacabana
      C05BM: {
        amount: 15,
        type: "percentage",
      },
      // wakiki
      C06BM: {
        amount: 15,
        type: "percentage",
      },
      C06CM: {
        amount: 15,
        type: "percentage",
      },
      // wash bag
      C08BM: {
        amount: 15,
        type: "percentage",
      },
      // travel bag
      C09BM: {
        amount: 15,
        type: "percentage",
      },
    },
  },
  {
    type: BundleDiscountType.bundle3,
    minimumItems: 4,
    range: ["4+"],
    discountsPerItem: {
      // ipanema
      C01BM: {
        amount: 20,
        type: "percentage",
      },
      C01CM: {
        amount: 20,
        type: "percentage",
      },
      C01PM: {
        amount: 20,
        type: "percentage",
      },
      C01VM: {
        amount: 20,
        type: "percentage",
      },
      C01DM: {
        amount: 20,
        type: "percentage",
      },
      // ipanema+
      C01BF: {
        amount: 20,
        type: "percentage",
      },
      C01CF: {
        amount: 20,
        type: "percentage",
      },
      C01PF: {
        amount: 20,
        type: "percentage",
      },
      C01VF: {
        amount: 20,
        type: "percentage",
      },
      C01DF: {
        amount: 20,
        type: "percentage",
      },
      // alexandria
      C03BM: {
        amount: 20,
        type: "percentage",
      },
      // alexandria+
      C03BF: {
        amount: 20,
        type: "percentage",
      },
      // bahia
      C02BM: {
        amount: 20,
        type: "percentage",
      },
      // bahia+
      C02BF: {
        amount: 20,
        type: "percentage",
      },
      // havena
      C04BF: {
        amount: 20,
        type: "percentage",
      },
      C04CF: {
        amount: 20,
        type: "percentage",
      },
      C04PF: {
        amount: 20,
        type: "percentage",
      },
      C04VF: {
        amount: 20,
        type: "percentage",
      },
      C04DF: {
        amount: 20,
        type: "percentage",
      },
      // copacabana
      C05BM: {
        amount: 20,
        type: "percentage",
      },
      // wakiki
      C06BM: {
        amount: 20,
        type: "percentage",
      },
      C06CM: {
        amount: 20,
        type: "percentage",
      },
      // wash bag
      C08BM: {
        amount: 20,
        type: "percentage",
      },
      // travel bag
      C09BM: {
        amount: 20,
        type: "percentage",
      },
    },
  },
  {
    type: BundleDiscountType.BuyGet, // New discount type
    minimumItems: 6, // Minimum items required for the discount
    range: ["6+"],
    discountsPerItem: {
      C01BM: { amount: 100, type: "percentage" },
      C01CM: { amount: 100, type: "percentage" },
      C01PM: { amount: 100, type: "percentage" },
      C01VM: { amount: 100, type: "percentage" },
      C01DM: { amount: 100, type: "percentage" },
      C01BF: { amount: 100, type: "percentage" },
      C01CF: { amount: 100, type: "percentage" },
      C01PF: { amount: 100, type: "percentage" },
      C01VF: { amount: 100, type: "percentage" },
      C01DF: { amount: 100, type: "percentage" },
      C03BM: { amount: 100, type: "percentage" },
      C03BF: { amount: 100, type: "percentage" },
      C02BM: { amount: 100, type: "percentage" },
      C02BF: { amount: 100, type: "percentage" },
      C04BF: { amount: 100, type: "percentage" },
      C04CF: { amount: 100, type: "percentage" },
      C04PF: { amount: 100, type: "percentage" },
      C04VF: { amount: 100, type: "percentage" },
      C04DF: { amount: 100, type: "percentage" },
      C05BM: { amount: 100, type: "percentage" },
      C06BM: { amount: 100, type: "percentage" },
      C06CM: { amount: 100, type: "percentage" },
      C08BM: { amount: 100, type: "percentage" },
      C09BM: { amount: 100, type: "percentage" },
    },
  },
];
