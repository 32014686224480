export enum SupportedPaymentMethod {
  creditCard = "creditCard",
  fawry = "fawry",
  cashOnDelivery = "cashOnDelivery",
  paymob = "paymob",
  tabby = "tabby",
  fawryWallet = "fawryWallet",
  referenceNumber = "referenceNumber",
  valU = "valU",
  wallet = "wallet",
  fawryBankInstallments = "fawryBankInstallments",
  fawryCreditCard = "fawryCreditCard",
  none = "none",
}

export enum FawryPaymentOption {
  fawry = "fawry",
  valU = "valU",
  wallet = "wallet",
  referenceNumber = "referenceNumber",
  fawryBankInstallments = "fawryBankInstallments",
  fawryCreditCard = "fawryCreditCard",
}
