import { useTranslations } from "next-intl";
import React from "react";
import { DiscountType } from "src/types/discount/DiscountType";

type DiscountItemProps = {
  currency: string;
  discountType: DiscountType;
  discountName: string;
  discountedAmount: number;
};

function DiscountItem({ currency, discountType, discountName, discountedAmount }: DiscountItemProps) {
  const t = useTranslations("SummaryDetails");

  return (
    <div id={`summary-details__${discountType}`} className="flex items-center justify-between text-green-600">
      <div className="cartItems-center flex items-center gap-1">
        <p>{t(discountType)}</p>
        {discountType !== DiscountType.credits && discountType !== DiscountType.bundle && (
          <p className="text-xs">({discountName})</p>
        )}
      </div>
      {/* #discounted-amount id added for e2e testing */}
      <p id="discounted-amount">
        {t("price", {
          amount: "-" + parseFloat(discountedAmount.toFixed(2)),
          currency,
        })}
      </p>
    </div>
  );
}

export default DiscountItem;
