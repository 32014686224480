import FacebookPixelEventsEnum from "../../types/enums/facebookPixelEvents.enums";
// import dayjs from "dayjs";
// import getOptionValueByName from "./inventory-helpers/getOptionValueByName";
import getCookie from "../cookie/getCookie";
import { Product } from "src/types/storefront";
import getLocalStorage from "../local-storage/getLocalStorage";
import { Cart } from "src/types/storefront/Cart";
import { captureException } from "@sentry/nextjs";
import sleep from "../sleep";
import highlightError from "../highlight/highlightError";
import { KoalaProduct } from "src/types/koala/KoalaProduct";
import { KoalaVariant } from "src/types/koala/KoalaVariant";

class FacebookPixelTracking {
  public constructor() {}

  protected facebookPixelTrack(name: string, data: object = {}, retries = 5) {
    const code = getLocalStorage("country_code");
    const env = process.env.NEXT_PUBLIC_ENV || "";
    const facebookPixelId =
      // env !== "production"
      //   ? process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_PROJECT_ID || ""
      // :
      code === "US"
        ? process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_US_PROJECT_ID || ""
        : // :
        code === "LB"
        ? process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_LB_PROJECT_ID || ""
        : process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_PROJECT_ID || "";
    const advancedMatching: any = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        // ReactPixel.init('3249689655287552', advancedMatching, options); 1
        // ReactPixel.init('1189600331630885', advancedMatching, options);  2
        // ReactPixel.init('662737591889517', advancedMatching, options); 3

        ReactPixel.init(facebookPixelId, advancedMatching, options);
        ReactPixel.track(name, data);
      })
      .catch(async (error) => {
        if (retries > 0) {
          console.count("React pixel retry");
          await sleep(3000);
          this.facebookPixelTrack(name, data, retries - 1);
        } else {
          highlightError(error, error.message);
          captureException(error.message);
        }
      });
  }

  protected checkoutDetails(cart: Cart) {
    let cartItemNames: string[] = [];
    cart.lines.map((item) => {
      cartItemNames.push(item.merchandise.product.title);
    });
    let cartItemIDs: string[] = [];
    cart.lines.map((item) => {
      cartItemIDs.push(item.merchandise.id);
    });
    return {
      content_ids: cartItemIDs,
      value: cart.cost.totalAmount.amount,
      currency: cart.cost.totalAmount.currencyCode,
      num_items: cart.lines.length,
      // contents: cartItemNames,
    };
  }

  protected purchaseDetails(totalPrice: number, currency: string) {
    return {
      value: totalPrice,
      currency: currency,
    };
  }

  protected pageViewDetails() {
    return {
      Environment: process.env.NEXT_PUBLIC_ENV,
    };
  }

  protected productDetails(product: Product) {
    return {
      content_name: product.title,
      currency: product.variants[0].price.currencyCode,
      value: product.variants[0].price.amount,
      content_ids: [product.variants[0].id],
      content_type: "product",
    };
  }

  protected koalaProductDetails(product: KoalaProduct) {
    return {
      content_name: product.name,
      currency: product.variants[0].currency,
      value: product.variants[0].price,
      content_ids: [product.variants[0]._id],
      content_type: "product",
    };
  }

  protected viewContentDetails(product: KoalaProduct, variant: KoalaVariant) {
    return {
      content_name: product.name,
      currency: product.variants[0].currency,
      value: product.variants[0].price,
      content_ids: [variant._id],
      content_type: "product",
    };
  }

  public pageViewed() {
    this.facebookPixelTrack(FacebookPixelEventsEnum.PAGE_VIEW, this.pageViewDetails());
  }

  public visit() {
    this.facebookPixelTrack(FacebookPixelEventsEnum.VISIT, this.pageViewDetails());
  }

  public addPaymentInfo(cart: Cart) {
    this.facebookPixelTrack(FacebookPixelEventsEnum.ADD_PAYMENT_INFO, this.checkoutDetails(cart));
  }

  public addToCart(product: Product) {
    this.facebookPixelTrack(FacebookPixelEventsEnum.ADD_TO_CART, this.productDetails(product));
  }

  public addToKoalaCart(product: KoalaProduct) {
    this.facebookPixelTrack(FacebookPixelEventsEnum.ADD_TO_CART, this.koalaProductDetails(product));
  }

  public completeRegistration() {
    this.facebookPixelTrack(FacebookPixelEventsEnum.COMPLETE_REGISTRATION);
  }

  public contact() {
    this.facebookPixelTrack(FacebookPixelEventsEnum.CONTACT);
  }

  public initiateCheckout(cart: Cart) {
    this.facebookPixelTrack(FacebookPixelEventsEnum.INITIATE_CHECKOUT, this.checkoutDetails(cart));
  }

  public lead(cart: Cart) {
    this.facebookPixelTrack(FacebookPixelEventsEnum.LEAD, this.checkoutDetails(cart));
  }

  public purchase(totalPrice: number, currency: string) {
    if (getCookie("_fbc") || getCookie("fbc") || getCookie("fbclid")) {
      this.facebookPixelTrack(FacebookPixelEventsEnum.PURCHASE, this.purchaseDetails(totalPrice, currency));
      console.log(
        "event and paramenters from Facebookpixel tracking inside",
        FacebookPixelEventsEnum.PURCHASE,
        totalPrice,
        currency
      );
    }
    console.log(
      "event and paramenters from Facebookpixel tracking outside",
      FacebookPixelEventsEnum.PURCHASE,
      totalPrice,
      currency
    );
  }

  public viewContent(product: KoalaProduct, variant: KoalaVariant) {
    this.facebookPixelTrack(FacebookPixelEventsEnum.VIEW_CONTENT, this.viewContentDetails(product, variant));
  }
}

const FacebookPixelTracker = new FacebookPixelTracking();
export default FacebookPixelTracker;
