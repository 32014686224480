import { Result, err, ok } from "neverthrow";
import { DeliveryOption } from "src/context/payment";
import ApiClient from "src/helpers/api-client/apiClient";
import {
  KoalaAttribute,
  KoalaCart,
  KoalaInsertCart,
  KoalaInsertLineItem,
  ShippingFees,
} from "src/types/koala/KoalaCart";
import {
  KoalaCheckout,
  KoalaCustomer,
  KoalaInsertCheckout,
  PickupPointMetadata,
  KoalaCheckoutFields,
} from "src/types/koala/KoalaCheckout";
import { KoalaProduct } from "src/types/koala/KoalaProduct";
import { KoalaVariant } from "src/types/koala/KoalaVariant";
import { CountryCode } from "src/types/localization/CountryCode";
import { FulconAdvancedResponse, FulconApiResponse } from "src/types/server/FulconApiResponse";
import koalaUtils from "src/helpers/koala/koalaUtils";
import Country from "src/helpers/localization-helpers/countryClass";
import { Gateway } from "src/types/database/Gateway";
import { KoalaOrder } from "src/types/koala/KoalaOrder";
import { DiscountDetails } from "src/types/discount/DiscountDetails";

const koalaApiClient = {
  apiBaseUrl: `${process.env.NEXT_PUBLIC_FULFILLMENT_CONNECTOR_URL}/koala`,
  apiClient: new ApiClient(),

  async getProducts({
    limit = 20,
    withVariants = true,
    countryCode = CountryCode.EG,
    collectionId,
    handle,
    fields,
  }: {
    limit?: number;
    withVariants?: boolean;
    countryCode?: CountryCode;
    collectionId?: string;
    handle?: string;
    fields?: string[];
  } = {}) {
    // Build the query
    const query = ["isDeleted=false"];
    query.push(`limit=${limit}`, `countryCode=${countryCode}`);
    if (withVariants) query.push(`populate=variants`);
    if (collectionId) query.push(`collectionIds=${collectionId}`);
    if (handle) query.push(`handle=${handle}`);
    if (fields) query.push(`select=${fields.join(",")}`);

    const result = await this.apiClient.get<FulconAdvancedResponse<KoalaProduct[]>>(
      `${this.apiBaseUrl}/products?${query.join("&")}`
    );

    return result;
  },

  async getVariantById(variantId: string) {
    const result = await this.apiClient.get<FulconAdvancedResponse<KoalaVariant[]>>(
      `${this.apiBaseUrl}/variants?_id=${variantId}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.data[0]);
  },

  async getCartById(cartId: string) {
    const result = await this.apiClient.get<FulconAdvancedResponse<KoalaCart[]>>(
      `${this.apiBaseUrl}/carts?_id=${cartId}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.data[0]);
  },

  async createCart(cart: KoalaInsertCart, countryData: Country, deliveryMethod: DeliveryOption) {
    const result = await this.apiClient.post<KoalaInsertCart, FulconApiResponse<KoalaCart>>(
      `${this.apiBaseUrl}/carts`,
      cart
    );
    if (result.isErr()) {
      return err(result.error);
    }

    const shippingFees = koalaUtils.calculateShippingFees(
      koalaUtils.getCartTotalQuantity(result.value.data.lineItems),
      result.value.data.totalPrice,
      countryData,
      deliveryMethod
    );
    return this.updateCartShipping(result.value.data._id, shippingFees);
  },

  async updateCartItems(
    cartId: string,
    lineItems: KoalaInsertLineItem[],
    countryData: Country,
    deliveryMethod: DeliveryOption
  ) {
    const result = await this.apiClient.put<{ lineItems: KoalaInsertLineItem[] }, FulconApiResponse<KoalaCart>>(
      `${this.apiBaseUrl}/carts/${cartId}/update-items`,
      { lineItems }
    );

    if (result.isErr()) {
      return err(result.error);
    }

    const shippingFees = koalaUtils.calculateShippingFees(
      koalaUtils.getCartTotalQuantity(result.value.data.lineItems),
      result.value.data.totalPrice,
      countryData,
      deliveryMethod
    );
    return this.updateCartShipping(result.value.data._id, shippingFees);
  },

  async updateCartAttributes(cartId: string, attributes: KoalaAttribute[]) {
    const result = await this.apiClient.put<{ attributes: KoalaAttribute[] }, FulconApiResponse<KoalaCart>>(
      `${this.apiBaseUrl}/carts/${cartId}`,
      { attributes }
    );

    return result;
  },

  async updateCartShipping(cartId: string, shippingFees: ShippingFees) {
    const result = await this.apiClient.put<{ shippingFees: ShippingFees }, FulconApiResponse<KoalaCart>>(
      `${this.apiBaseUrl}/carts/${cartId}/update-shipping-fees`,
      { shippingFees }
    );

    return result;
  },

  async applyDiscountCode(cartId: string, email: string, discountCode: string) {
    const result = await this.apiClient.put<{ email: string; discountCode: string }, FulconApiResponse<KoalaCart>>(
      `${this.apiBaseUrl}/carts/${cartId}/apply-discount-code`,
      { email, discountCode }
    );

    return result;
  },

  async removeDiscountCode(cartId: string, discountCode: string) {
    const result = await this.apiClient.put<{ discountCode: string }, FulconApiResponse<KoalaCart>>(
      `${this.apiBaseUrl}/carts/${cartId}/remove-discount-code`,
      { discountCode }
    );

    return result;
  },

  async applyDiscount(cartId: string, discount: DiscountDetails) {
    const result = await this.apiClient.put<DiscountDetails, FulconApiResponse<KoalaCart>>(
      `${this.apiBaseUrl}/carts/${cartId}/apply-discount`,
      discount
    );

    return result;
  },

  async createCheckout(cartId: string, customer: KoalaCustomer) {
    const result = await this.apiClient.post<KoalaInsertCheckout, FulconApiResponse<KoalaCheckout>>(
      `${this.apiBaseUrl}/checkouts`,
      {
        cartId,
        customer,
      }
    );

    return result;
  },

  async updateCheckoutCustomer(checkoutId: string, customer: KoalaCustomer) {
    const result = await this.apiClient.put<{ customer: KoalaCustomer }, FulconApiResponse<KoalaCheckout>>(
      `${this.apiBaseUrl}/checkouts/${checkoutId}`,
      { customer }
    );

    return result;
  },

  async updateDeliveryMethod(
    checkoutId: string,
    deliveryMethod: DeliveryOption,
    pickupPointMetadata?: PickupPointMetadata
  ) {
    const result = await this.apiClient.put<
      { deliveryMethod: DeliveryOption; pickupPointMetadata?: PickupPointMetadata },
      FulconApiResponse<KoalaCheckout>
    >(`${this.apiBaseUrl}/checkouts/${checkoutId}`, { deliveryMethod, pickupPointMetadata });

    return result;
  },

  async updateCheckoutGateway(checkoutId: string, gateway: Gateway) {
    const result = await this.apiClient.put<{ paymentGateway: Gateway }, FulconApiResponse<KoalaCheckout>>(
      `${this.apiBaseUrl}/checkouts/${checkoutId}`,
      { paymentGateway: gateway }
    );

    return result;
  },

  async getCheckoutById(checkoutId: string) {
    const result = await this.apiClient.get<FulconAdvancedResponse<KoalaCheckout[]>>(
      `${this.apiBaseUrl}/checkouts?_id=${checkoutId}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    return ok(result.value.data[0]);
  },

  async updateCheckout(checkoutId: string, fieldsToUpdate: KoalaCheckoutFields) {
    const result = await this.apiClient.put<{ attributes: KoalaCheckoutFields }, FulconApiResponse<KoalaCart>>(
      `${this.apiBaseUrl}/checkouts/${checkoutId}/update-checkout`,
      { attributes: fieldsToUpdate }
    );

    return result;
  },

  async convertCheckoutToOrder(checkoutId: string, pl3Connector: string, scopes: string[]) {
    const result = await this.apiClient.put<{ pl3Connector: string; scopes: string[] }, FulconApiResponse<KoalaOrder>>(
      `${this.apiBaseUrl}/checkouts/${checkoutId}/complete`,
      { pl3Connector, scopes }
    );

    return result;
  },

  async convertManufactorCheckoutToOrder(checkoutId: string, pl3Connector: string, scopes: string[]) {
    const result = await this.apiClient.put<{ pl3Connector: string; scopes: string[] }, FulconApiResponse<KoalaOrder>>(
      `${this.apiBaseUrl}/checkouts/custom/${checkoutId}/complete`,
      { pl3Connector, scopes }
    );

    return result;
  },

  async getOrderById(orderId: string) {
    const result = await this.apiClient.get<FulconAdvancedResponse<KoalaOrder[]>>(
      `${process.env.NEXT_PUBLIC_FULFILLMENT_CONNECTOR_URL}/orders/?_id=${orderId}`
    );

    if (result.isErr()) {
      return err(result.error);
    }

    if (result.value.data.length == 0) {
      return err({ errorMessage: `Couldn't find order with id: ${orderId}` });
    }

    return ok(result.value.data[0]);
  },
};

export default koalaApiClient;
