import { useRouter } from "next/router";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import getLocalStorage from "src/helpers/local-storage/getLocalStorage";
import setLocalStorage from "src/helpers/local-storage/setLocalStorage";
import Country from "src/helpers/localization-helpers/countryClass";
import useCountryData from "src/hooks/useCountryData";
import { DraftOrder } from "src/types/shopify-admin";
import { DraftOrderContext, DraftOrderContextProps } from "./draftOrder";
import isDiscountNameAppliedToDraftOrder from "src/helpers/draft-order/isDiscountNameAppliedToDraftOrder";
import { ServerApiClient } from "src/apis/server-api-client";
import { captureException } from "@sentry/nextjs";
import { ParsedUrlQuery } from "querystring";
import { ShemsiApiClient } from "src/apis/shemsi-api-client";
import { DBUser } from "src/types/database/DBUser";
import { ReferralConfig } from "src/types/localization/ReferralConfig";
import { AuthenticationContext, AuthenticationContextProps } from "./authentication";
import getReferralConfig from "src/helpers/user/getReferralConfig";
import retryAsync from "src/helpers/retryAsync";
import { useStoreActions, useStoreState } from "src/hooks/storeHooks";

const serverApiClient = new ServerApiClient();
const shemsiApiClient = new ShemsiApiClient();

function setReferralCodeFromURL(query: ParsedUrlQuery, setReferralCode: Dispatch<SetStateAction<string>>) {
  let referralCodeParam = query["ref"];

  if (typeof referralCodeParam === "string") {
    referralCodeParam = decodeURIComponent(referralCodeParam);
    setLocalStorage("referral_code", referralCodeParam);
    setReferralCode(referralCodeParam);
  }
}

function setReferralCodeFromStorage(setReferralCode: Dispatch<SetStateAction<string>>) {
  const storageReferralCode = getLocalStorage("referral_code");

  if (storageReferralCode) {
    setReferralCode(storageReferralCode);
  }
}

function addUsedReferralCodeToLocalStorage(referralCode: string) {
  const storageUsedReferralCodes: string[] | null = getLocalStorage("used_referral_codes");
  if (!storageUsedReferralCodes) {
    setLocalStorage("used_referral_codes", [referralCode]);
  } else {
    setLocalStorage("used_referral_codes", storageUsedReferralCodes.concat(referralCode));
  }
}

async function getAndSetReferralConfig(
  referralCode: string,
  countryData: Country,
  setReferralConfig: Dispatch<SetStateAction<ReferralConfig | null>>,
  user: DBUser | null,
  isUserLoading: boolean
) {
  // Exit if country doesn't support referral feature
  if (!countryData.referralConfig) return;

  // Exit if referral code is used in a previous order
  if (countryData.referralConfig.newRefereeOnly) {
    const storageUsedReferralCodes: string[] = getLocalStorage("used_referral_codes") || [];
    const usedOnce = storageUsedReferralCodes.includes(referralCode);
    if (usedOnce) {
      console.warn(`🤷🏻‍♀️: Referral code: ${referralCode} is used for a previous order`);
      return;
    }
  }

  // Exit if user uses his own referral code
  if (isUserLoading || user?.referralConfigs.some((config) => config.code === referralCode)) {
    return;
  }

  const userResult = await retryAsync(async () => await serverApiClient.getUserByReferralCode(referralCode));

  if (userResult.isErr()) {
    console.error(userResult.error);
    captureException(userResult.error.errorMessage);
    return;
  }

  const referralConfig = getReferralConfig(userResult.value, countryData);
  setReferralConfig(referralConfig);
}

async function applyReferralDiscountToDraftOrder(
  referralConfig: ReferralConfig,
  countryData: Country,
  draftOrder: DraftOrder,
  setDraftOrder: Dispatch<SetStateAction<DraftOrder | null>>,
  user: DBUser | null
) {
  // Exit if referral discount is already applied to draft order
  if (isDiscountNameAppliedToDraftOrder(draftOrder, referralConfig.code)) {
    console.warn(`🤷🏻‍♀️: Referral code: ${referralConfig.code} is already applied to draft order: ${draftOrder.id}`);
    return;
  }

  // Exit if user uses his own referral code
  if (user && user.referralConfigs.some((config) => config.code === referralConfig.code)) {
    return;
  }

  // Exit if referral code is used in a previous order
  if (countryData.referralConfig?.newRefereeOnly) {
    const storageUsedReferralCodes: string[] = getLocalStorage("used_referral_codes") || [];
    const usedOnce = storageUsedReferralCodes.includes(referralConfig.code);
    if (usedOnce) {
      console.warn(`🤷🏻‍♀️: Referral code: ${referralConfig.code} is used for a previous order`);
      return;
    }
  }

  // Let's go 🚀
  const applyReferralDiscountResult = await shemsiApiClient.applyReferralDiscount(
    countryData.code,
    referralConfig.code,
    draftOrder.id
  );

  if (applyReferralDiscountResult.isErr()) {
    captureException(JSON.stringify(applyReferralDiscountResult.error));
    return;
  }

  setDraftOrder(applyReferralDiscountResult.value.updatedDraftOrder);
}

export interface ReferralContextProps {
  activeReferralConfig: ReferralConfig | null;
  referralModalIsOpen: boolean;
  setReferralModalIsOpen: Dispatch<SetStateAction<boolean>>;
  resetReferral(): void;
}

export const ReferralContext = createContext<ReferralContextProps | null>(null);

export default function ReferralProvider({ children }: { children: ReactNode }) {
  // dependencies
  const { user, isUserLoading } = useContext(AuthenticationContext) as AuthenticationContextProps;
  const cart = useStoreState((state) => state.cart);
  const applyReferralDiscountThunk = useStoreActions((actions) => actions.applyReferralDiscountThunk);
  const { draftOrder, setDraftOrder } = useContext(DraftOrderContext) as DraftOrderContextProps;
  const countryData = useCountryData();
  const { isReady, query } = useRouter();

  // states
  const [referralCode, setReferralCode] = useState<string>("");
  const [referralConfig, setReferralConfig] = useState<ReferralConfig | null>(null);
  const [referralModalIsOpen, setReferralModalIsOpen] = useState<boolean>(false);
  const [isModalSeenOnce, setIsModalSeenOnce] = useState(false);

  console.log("🦜", { referralConfig, referralCode });

  const resetReferral = () => {
    // If there's a referral code, add it to used ones
    const storageReferralCode = getLocalStorage("referral_code");
    if (storageReferralCode) addUsedReferralCodeToLocalStorage(storageReferralCode);

    setReferralCode("");
    setReferralConfig(null);
  };

  // effects
  useEffect(
    function showReferralModalIfReferralConfigExists() {
      if (referralConfig && !isModalSeenOnce) {
        setReferralModalIsOpen(true);
        setIsModalSeenOnce(true);
      }
    },
    [referralConfig, isModalSeenOnce]
  );

  /**
   * Checks referral code in local storage
   * and sets referralCode state
   */
  useEffect(() => {
    setReferralCodeFromStorage(setReferralCode);
  }, []);

  /**
   * Checks if a user visits with a URL containing "ref" query param
   * Get the customer with this referral code (affiliate) to validate that the referral code exists
   * set the state and local storage with the referral code
   * This is considered the trigger for the whole referral workflow
   */
  useEffect(() => {
    if (isReady) {
      setReferralCodeFromURL(query, setReferralCode);
    }
  }, [isReady]);

  /**
   * If referral code is set
   * get referral config
   */
  useEffect(() => {
    if (referralCode && countryData) {
      getAndSetReferralConfig(referralCode, countryData, setReferralConfig, user, isUserLoading);
    }
  }, [referralCode, countryData, user, isUserLoading]);

  /**
   * Check if draft order exists
   * apply referral reward
   */
  // useEffect(() => {
  //   if (countryData && referralConfig && draftOrder) {
  //     applyReferralDiscountToDraftOrder(referralConfig, countryData, draftOrder, setDraftOrder, user);
  //   }
  // }, [draftOrder, referralConfig, countryData, user]);

  useEffect(() => {
    if (countryData && referralConfig && cart) {
      // Check if the discount is already applied
      const isDiscountApplied = cart.discounts?.some((discount) => discount.name === referralConfig.code);

      if (!isDiscountApplied) {
        applyReferralDiscountThunk({ referralConfig, countryData, cart, user });
      } else {
        console.warn(`🤷🏻‍♀️: Referral discount ${referralConfig.code} is already applied.`);
      }
    }
  }, [cart, referralConfig, countryData, user]);

  return (
    <ReferralContext.Provider
      value={{
        activeReferralConfig: referralConfig,
        referralModalIsOpen,
        setReferralModalIsOpen,
        resetReferral,
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
}
