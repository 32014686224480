import { TrashIcon } from "@heroicons/react/24/outline";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { ChangeEvent, useContext, useState } from "react";
import SpinIcon from "src/components/icon/SpinIcon";
import QuantityControl from "src/components/quantity-control/QuantityControl";
import classNames from "src/helpers/classNames";
import { TagIcon } from "@heroicons/react/24/solid";
import useCountryData from "src/hooks/useCountryData";
import { useStoreActions } from "src/hooks/storeHooks";
import useNeverthrowAsync from "src/hooks/useNeverthrowAsync";
import { KoalaCart, KoalaLineItem } from "src/types/koala/KoalaCart";
import useLineItemVariant from "src/hooks/useLineItemVariant";
import { trim } from "lodash";
import koalaUtils from "src/helpers/koala/koalaUtils";
import usePriceBeforeDiscount from "src/hooks/usePriceBeforeDiscount";
import { PaymentContext } from "src/context/payment";
import Country from "src/helpers/localization-helpers/countryClass";
import { BundleDiscountType } from "src/types/bundle-discounts/BundleDiscountType";
import useRtl from "src/hooks/useRtl";

type KoalaCartSideBarItemProps = {
  cart: KoalaCart;
  lineItem: KoalaLineItem;
  removeFromCart?: boolean;
};

function KoalaCartSideBarItem({ cart, lineItem, removeFromCart = false }: KoalaCartSideBarItemProps) {
  const t = useTranslations("CartSidebar");
  const countryData = useCountryData();
  const { deliveryOption } = useContext(PaymentContext);
  const rtl = useRtl();

  const updateCartItemsThunk = useStoreActions((state) => state.updateCartItemsThunk);
  const variant = useLineItemVariant(lineItem.variantId);
  const quantityAvailable = variant ? variant.quantity : NaN;
  const isBuyGetBundle = lineItem.discount?.name === BundleDiscountType.BuyGet;

  const [quantityInput, setQuantityInput] = useState<"" | number>(lineItem.quantity);
  const [quantityError, setQuantityError] = useState<string | null>(null);

  const [handleUpdateItemQuantity, updateItemLoading] = useNeverthrowAsync(
    async function updateItems(quantity: number) {
      const cartResult = await updateCartItemsThunk({
        cartId: cart._id,
        newItem: {
          variantId: lineItem.variantId,
          sku: lineItem.sku,
          quantity,
          price: lineItem.price,
        },
        countryData: countryData as Country,
        deliveryMethod: deliveryOption,
      });
      return cartResult;
    },
    {
      uiSuccessMessage: `Successfully, Updated item: ${lineItem.name}`,
      uiErrorMessage: "Sorry, something went wrong while updating the item's quantity",
    }
  );

  const handleQuantityInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const inputToInteger = parseInt(value);
    const isInteger = Number.isInteger(inputToInteger);
    if (isInteger) {
      if (inputToInteger > quantityAvailable) {
        setQuantityError(t("max_quantity_message"));
      } else if (inputToInteger < 1) {
        handleUpdateItemQuantity(0);
      } else {
        await handleUpdateItemQuantity(inputToInteger);
        setQuantityInput(inputToInteger);
      }
    } else if (value === "") {
      setQuantityInput(value);
    }
  };

  const handleQuantityPlusClick = async () => {
    if (quantityInput === "") {
      await handleUpdateItemQuantity(1);
      setQuantityInput(1);
    } else if (Number.isInteger(quantityInput)) {
      if (lineItem.quantity >= quantityAvailable) setQuantityError(t("max_quantity_message"));
      else {
        await handleUpdateItemQuantity(Number(quantityInput) + 1);
        setQuantityInput((oldQuantity) => Number(oldQuantity) + 1);
      }
    }
  };

  const handleQuantityMinusClick = async () => {
    if (quantityInput === "") {
      setQuantityError(t("min_quantity_message"));
    } else if (Number.isInteger(quantityInput)) {
      await handleUpdateItemQuantity(Number(quantityInput) - 1);
      setQuantityInput((oldQuantity) => Number(oldQuantity) - 1);
    }
  };

  const [productName, variantOptions] = lineItem.name?.split("-").map(trim) || ["1", "2"];
  let price = lineItem.price;
  if (lineItem.discount) {
    const discountAmount = koalaUtils.calculateSingleDiscount(price, lineItem.discount);
    price -= discountAmount;
  }
  const priceBeforeDiscount = usePriceBeforeDiscount(lineItem.price, productName.toLowerCase());
  const isOutOfStock = lineItem.quantity > quantityAvailable;
  const noOfFreeItems = lineItem.discount?.value ? Math.round((lineItem.discount.value / 100) * lineItem.quantity) : 0;

  /**
   * images
   */
  const variantImages = lineItem.images;

  return (
    <li>
      <div className="flex items-start">
        <Image
          className="me-5 w-28 shrink-0"
          src={variantImages[0].url}
          alt={""}
          sizes={"150px"}
          width={0}
          height={0}
        />
        <div className="flex flex-col space-y-2">
          <p className="font-semibold">{productName}</p>

          <p className="flex text-sm">{variantOptions}</p>

          <div className="flex items-center gap-2">
            {/* Generated Price */}
            {priceBeforeDiscount && priceBeforeDiscount > Number(lineItem.price) ? (
              <p className="text-sm font-semibold text-red-500 line-through">
                {t("price", {
                  amount: Math.round(priceBeforeDiscount),
                  currency: lineItem.currency,
                })}
              </p>
            ) : (
              lineItem.discount &&
              !isBuyGetBundle && (
                <p className="text-sm font-semibold text-gray-500 line-through">
                  {t("price", {
                    amount: Math.round(lineItem.price) * lineItem.quantity,
                    currency: lineItem.currency,
                  })}
                </p>
              )
            )}
            <p className="font-semibold">
              {t("price", {
                amount: isBuyGetBundle ? Math.round(lineItem.price) : Math.round(price),
                currency: lineItem.currency,
              })}
            </p>
            {
              <p className="flex justify-end text-xs">
                <span className={classNames(rtl ? "hidden" : "block")}>x{lineItem.quantity} =&nbsp;</span>
                <span className={classNames(rtl ? "block" : "hidden")}>{lineItem.quantity}x =&nbsp;</span>
                <span>
                  {t("price", {
                    amount: Math.round(price) * lineItem.quantity,
                    currency: lineItem.currency,
                  })}
                </span>
              </p>
            }
          </div>
          <div className="flex gap-2">
            {lineItem.discount &&
              (isBuyGetBundle ? (
                <span className={classNames(`discount-tag`)}>
                  {noOfFreeItems} {t("free")}
                </span>
              ) : (
                <span className={classNames(`discount-tag`)}>
                  <TagIcon className="h-4 w-4 fill-green-100 stroke-green-900" />-{lineItem.discount.value}%
                </span>
              ))}
          </div>
          <div className="flex items-center">
            {/* Quantity control */}
            <QuantityControl
              quantity={quantityInput}
              quantityError={quantityError}
              setQuantityError={setQuantityError}
              onInputChange={handleQuantityInputChange}
              onPlusClick={handleQuantityPlusClick}
              onMinusClick={handleQuantityMinusClick}
              disabled={updateItemLoading || isOutOfStock}
              outOfStock={isOutOfStock}
            />
            {/* Remove from cart button */}
            {removeFromCart && (
              <button
                type="button"
                disabled={updateItemLoading}
                className="ms-6 rounded-sm"
                onClick={() => handleUpdateItemQuantity(0)}
              >
                <span className="sr-only">Remove item</span>
                <TrashIcon
                  className={classNames("h-6 w-6", isOutOfStock ? "stroke-red-700" : "stroke-gray-900")}
                  aria-hidden="true"
                />
              </button>
            )}

            {/* Spinner */}
            {updateItemLoading && <SpinIcon className="ms-4 !text-gray-900" />}
          </div>
          {/* Out of stock message */}
          {quantityAvailable == 0 && <p className="text-sm text-red-600">{t("variant_out_of_stock")}</p>}
          {isOutOfStock && (
            <p className="text-sm text-red-600">
              {t("variant_not_enough_quantity", {
                quantityAvailable,
              })}
            </p>
          )}
        </div>
      </div>

      {quantityError && <p className="error-message">{quantityError}</p>}
    </li>
  );
}

export default KoalaCartSideBarItem;
