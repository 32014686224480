import FacebookPixelTracker from "src/helpers/event-tracking/facebookPixelTracking";
import mixpanelTracker from "src/helpers/event-tracking/mixpanelTracking";
import googleAnalyticsTracker from "src/helpers/event-tracking/googleAnalyticsTracking";
import { CheckoutUserInformation } from "src/context/CheckoutUserInformation";
import { Product } from "src/types/storefront";
import { Cart } from "src/types/storefront/Cart";
import snapchatPixelTracker from "./snapchatPixelTracking";
import tiktokPixelTracker from "./tiktokPixelTracking";
import { SurveyData } from "src/types/dr-shemsi/DrShemsiSurvey";
import { ChatLog } from "src/types/dr-shemsi/ChatLog";
import { WaitingListData } from "src/types/dr-shemsi/WaitingListData";
import { DBUser } from "src/types/database/DBUser";
import { BundleSource } from "src/types/bundle-discounts/BundleSource";
import { KoalaProduct } from "src/types/koala/KoalaProduct";
import { KoalaCart } from "src/types/koala/KoalaCart";
import { KoalaVariant } from "src/types/koala/KoalaVariant";

class EventTrackingManager {
  public constructor() {}

  // Mixpanel Events
  public experimentStarted(experimentName: string, experiment_id: string, variantName: number, $source: string) {
    mixpanelTracker.experimentStarted(experimentName, experiment_id, variantName, ($source = "growthbook"));
  }

  public pageViewed() {
    mixpanelTracker.pageViewed();
    googleAnalyticsTracker.pageViewed();
    FacebookPixelTracker.pageViewed();
    snapchatPixelTracker.pageViewed();
    tiktokPixelTracker.pageViewed();
  }

  public visit() {
    mixpanelTracker.visit();
    googleAnalyticsTracker.visit();
    FacebookPixelTracker.visit();
    tiktokPixelTracker.visit();
  }

  public error(sentryEventID: string, errorType: string, errorValue: string, requestUrl: string) {
    mixpanelTracker.error(sentryEventID, errorType, errorValue, requestUrl);
  }

  public subscribeToNewsletter(emailAddress: string, source: string, discountPercentage: number) {
    mixpanelTracker.subscribeToNewsletter(emailAddress, source, discountPercentage);
    googleAnalyticsTracker.subscribeToNewsletter(emailAddress, source, discountPercentage);
    tiktokPixelTracker.contact(emailAddress, source, discountPercentage);
  }

  public copyReferralLink(user: DBUser, referralLink: string, page: string) {
    mixpanelTracker.copyReferralLink(user, referralLink, page);
  }

  public createBundle(cart: Cart, source: BundleSource) {
    mixpanelTracker.createBundle(cart, source);
  }

  public createKoalaBundle(cart: KoalaCart, source: BundleSource) {
    mixpanelTracker.createKoalaBundle(cart, source);
  }

  public shareReferralLink(user: DBUser, referralLink: string, socialApp: string, page: string) {
    mixpanelTracker.shareReferralLink(user, referralLink, socialApp, page);
  }

  public sentEmailFrom(emailAddress: string) {
    // this function should be invoked
  }

  public productViewed(product: Product) {
    mixpanelTracker.productViewed(product);
    googleAnalyticsTracker.productViewed(product);
  }

  public koalaProductViewed(product: KoalaProduct) {
    mixpanelTracker.koalaProductViewed(product);
    googleAnalyticsTracker.koalaProductViewed(product);
  }

  public clickOnVideo(videoName: string) {
    mixpanelTracker.clickOnVideo(videoName);
    googleAnalyticsTracker.clickOnVideo(videoName);
  }

  // public variantChanged(
  //   productName: string,
  //   variant: Variant,
  //   selectedOptions: SelectedOption[]
  // ) {
  //   mixpanelTracker.variantChanged(productName, variant, selectedOptions);
  //   googleAnalyticsTracker.variantChanged(
  //     productName,
  //     variant,
  //     selectedOptions
  //   );
  // }

  public addPaymentInfo(cart: Cart, paymentType: string) {
    FacebookPixelTracker.addPaymentInfo(cart);
    googleAnalyticsTracker.addPaymentInfo(cart, paymentType);
    mixpanelTracker.addPaymentInfo(cart, paymentType);
    tiktokPixelTracker.addPaymentInfo(cart);
  }

  public completeRegistration(cart: Cart, userInformation: CheckoutUserInformation) {
    FacebookPixelTracker.completeRegistration();
    mixpanelTracker.setUserData(userInformation);
    googleAnalyticsTracker.addShippingInfo(cart, userInformation);
    mixpanelTracker.addShippingInfo(cart, userInformation);
    tiktokPixelTracker.completeRegistration(cart, userInformation);
  }

  // facebookPixel Events

  public contact(email: string) {
    FacebookPixelTracker.contact();
    googleAnalyticsTracker.sentEmailFrom(email);
  }

  public lead(cart: Cart) {
    FacebookPixelTracker.lead(cart);
    googleAnalyticsTracker.generateLead(cart);
  }

  // common Events
  public purchase(totalPrice: string, currency: string) {
    mixpanelTracker.purchase(+totalPrice, currency);
    // googleAnalyticsTracker.purchase(+totalPrice, currency);
    FacebookPixelTracker.purchase(+totalPrice, currency);
  }
  // public purchase(cartSize: number, cartItemIDs: string[],cartItemNames: string[],taxAmount:string, totalPrice: string, currency: string) {
  //   mixpanelTracker.purchase(cartSize, cartItemIDs, cartItemNames,+taxAmount, +totalPrice, currency);
  //   FacebookPixelTracker.purchase(+totalPrice, currency)
  // }

  public addToCart(product: Product, quantity: number) {
    mixpanelTracker.addedToCart(product);
    googleAnalyticsTracker.addedToCart(product);
    FacebookPixelTracker.addToCart(product);
    snapchatPixelTracker.addedToCart(product);
    tiktokPixelTracker.addedToCart(product, quantity);
  }

  public addToKoalaCart(product: KoalaProduct, quantity: number) {
    mixpanelTracker.addedToKoalaCart(product);
    googleAnalyticsTracker.addedToKoalaCart(product);
    FacebookPixelTracker.addToKoalaCart(product);
    snapchatPixelTracker.addedToKoalaCart(product);
    tiktokPixelTracker.addedToKoalaCart(product, quantity);
  }

  public removeFromCart(product: Product) {
    googleAnalyticsTracker.removedFromCart(product);
  }

  public initiateCheckout(cart: Cart) {
    mixpanelTracker.viewCheckoutPage(cart);
    googleAnalyticsTracker.viewCheckoutPage(cart);
    FacebookPixelTracker.initiateCheckout(cart);
    tiktokPixelTracker.initiateCheckout(cart);
  }

  public visitDrShemsi() {
    mixpanelTracker.visitDrShemsi();
  }

  public drShemsiSurveySubmission(surveyData: SurveyData) {
    mixpanelTracker.drShemsiSurveySubmission(surveyData);
  }

  public askDrShemsi(surveyData: SurveyData, chatLog: ChatLog[]) {
    mixpanelTracker.askDrShemsi(surveyData, chatLog);
  }

  public joinDrShemsiWaitingList(userData: WaitingListData) {
    mixpanelTracker.joinDrShemsiWaitingList(userData);
  }

  public viewContent(product: KoalaProduct, variant: KoalaVariant) {
    FacebookPixelTracker.viewContent(product, variant);
  }
}

const EventTracker = new EventTrackingManager();
export default EventTracker;
